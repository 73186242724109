import { useFetcher } from '@remix-run/react'
import { useState } from 'react'
import { Modal } from '~/design-system'
import SignIn from '../../../booking/components/SignIn'
import ForgotPassword from '../../../booking/components/ForgotPassword'

function LoginModal({
  isOpen,
  alertMessage,
  setShowLoginModal,
  setAlertMessage,
}: {
  isOpen: boolean
  alertMessage: React.ReactNode
  setShowLoginModal: (isOpen: boolean) => void
  setAlertMessage: (alertMessage: React.ReactNode) => void
}) {
  const signInFetcher = useFetcher<{ errorMessage: string }>()
  const forgotPasswordFetcher = useFetcher<{
    ok: boolean
    errorMessage: string
  }>()
  const [isSignIn, setIsSignIn] = useState(true)
  const [email, setEmail] = useState<string>('')

  const onClose = () => {
    setShowLoginModal(false)
    setEmail('')
    setAlertMessage(null)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col justify-center items-center w-modal-container-mobile lg:w-medium-modal lg:max-w-page-container">
        {isSignIn ? (
          <SignIn
            fetcher={signInFetcher}
            changeToForgotPasswordForm={() => setIsSignIn(false)}
            closeModal={() => {
              setAlertMessage(null)
              setShowLoginModal(false)
            }}
            email={email}
            setEmail={setEmail}
            alertMessage={alertMessage}
          />
        ) : (
          <ForgotPassword
            fetcher={forgotPasswordFetcher}
            changeToSignInForm={() => setIsSignIn(true)}
            email={email}
            setEmail={setEmail}
          />
        )}
      </div>
    </Modal>
  )
}

export default LoginModal
