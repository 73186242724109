import { useContext } from 'react'

import { Patient, LoginModalContext } from '~/domains/accounts'
import Logo from './Logo'
import UserMenu from './UserMenu'
import ChatButton from './ChatButton'
import { Conversation } from '~/domains/secureCareInbox'

const Header = ({
  patient,
  conversations,
}: {
  patient?: Promise<Patient>
  conversations?: Promise<Conversation[]>
}) => {
  const { showLoginModal } = useContext(LoginModalContext)
  return (
    <header>
      <nav className="bg-white border-b-1 flex-1 relative z-30 border-neutral-20-borders-dividers h-header-desktop px-5 md:px-3">
        <div className="flex items-center justify-between h-full mx-auto lg:px-2">
          <Logo />
          <div className="grow"></div>
          {patient && conversations && (
            <ChatButton conversations={conversations} />
          )}
          <UserMenu patient={patient} showLoginModal={showLoginModal} />
        </div>
      </nav>
    </header>
  )
}

export default Header
