import * as Sentry from '@sentry/remix'
import { data, LoaderFunction, MetaFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigate,
  useRouteError,
} from '@remix-run/react'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { AppGlobalsScript } from './AppGlobals'
import './tailwind.css'
import { patientPortalCookie } from './patientPortalCookie.server'
import { useInitializeAnalytics, useTrackPageView } from './lib/analytics'
import { ErrorFallback, Header, createPageName } from './common'
import {
  convertBookingSessionToAccountSession,
  getIntercomAuth,
  IntercomAuth,
  maybeGetAccountSession,
} from '~/domains/accounts/server'
import {
  getCurrentUser,
  User,
  getPatient,
  Patient,
  hasBookingSession,
  getSessionDataFromBookingSession,
  useUserLoggedIn,
  LoginModalProvider,
} from '~/domains/accounts'

import { createClient, isAccessDeniedError } from './lib/api'
import { Conversation, getConversations } from '~/domains/secureCareInbox'
import { useIntercom } from '~/lib/intercom/useIntercom'
import { GlobalLoader } from './common/components/GlobalLoader'
import { getAppGlobals, setGlobalData } from './global-data'
import {
  getFeatures,
  getTargetingAttributes,
  Features,
  TargetingAttributes,
} from '~/lib/experimentation/server'
import { useSetupExperimentation } from '~/lib/experimentation'
import Routes from './common/routes'
import deleteSessionCookie from './lib/cookies/deleteSessionCookie'
import { useEffect } from 'react'
import { Loader } from '~/design-system'

if (setGlobalData) {
  await setGlobalData()
}

export type LoaderData = {
  analyticsWriteKey: string
  analyticsDataPlaneUrl: string
  user: User | undefined
  patient: Promise<Patient | null> | undefined
  conversations: Promise<Conversation[]> | undefined
  intercomAppId: string
  intercomApiBase: string
  features: Features
  attributes: TargetingAttributes
  intercomAuth: Promise<IntercomAuth | null> | undefined
}

export const meta: MetaFunction = () => {
  return [{ title: createPageName() }]
}

export const loader: LoaderFunction = async ({ request }) => {
  let sessionData
  const headers = new Headers()

  headers.append(
    'Set-Cookie',
    await patientPortalCookie.serialize({
      // forces the booking flow to redirect to the new patient portal
      remixPatientPortal: true,
    }),
  )

  if (hasBookingSession(request)) {
    const { sessionCookie, deleteBookingSession } =
      await convertBookingSessionToAccountSession(request)
    headers.append('Set-Cookie', sessionCookie)
    headers.append('Set-Cookie', deleteBookingSession)
    sessionData = getSessionDataFromBookingSession(request)
  } else sessionData = await maybeGetAccountSession(request)

  let user: User | undefined
  let patient: Promise<Patient | null> | undefined
  let conversations: Promise<Conversation[]> | undefined
  let intercomAuth: Promise<IntercomAuth | null> | undefined

  if (sessionData) {
    const api = createClient({ ...sessionData, request })
    user = await getCurrentUser(api)
    patient = user && getPatient(api)
    conversations = getConversations(api)
    intercomAuth = getIntercomAuth(api)
  }
  const features = await getFeatures()
  const { attributes, serializeAttributesCookie } =
    await getTargetingAttributes({ request, user })
  headers.append('Set-Cookie', await serializeAttributesCookie())

  return data<LoaderData>(
    {
      patient,
      user,
      conversations,
      features,
      attributes,
      intercomAuth,
      analyticsWriteKey: process.env.RUDDERSTACK_WRITE_KEY || '',
      analyticsDataPlaneUrl: process.env.RUDDERSTACK_DATA_PLANE_URL || '',
      intercomAppId: process.env.INTERCOM_APP_ID || '',
      intercomApiBase: process.env.INTERCOM_API_BASE || '',
    },
    {
      headers,
    },
  )
}

export const handle = {
  pageName: 'Root Page',
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="scroll-smooth">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="apple-itunes-app" content="app-id=1003540833" />
        <Meta />
        <Links />
      </head>
      <body className="h-screen flex flex-col">
        <div id="backdrop-root" className="grow">
          {children}
        </div>
        <ScrollRestoration />
        <AppGlobalsScript />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  const {
    analyticsWriteKey,
    analyticsDataPlaneUrl,
    user,
    intercomAppId,
    intercomApiBase,
    patient,
    conversations,
    features,
    attributes,
    intercomAuth,
  } = useLoaderData<LoaderData>()

  useIntercom({
    intercomAuth,
    intercomAppId,
    intercomApiBase,
  })
  const { growthBook } = useSetupExperimentation(
    features as Features,
    attributes,
  )

  useInitializeAnalytics({
    analyticsWriteKey,
    analyticsDataPlaneUrl,
  })
  useTrackPageView()
  useUserLoggedIn(() => {
    const { id, email } = user as User
    Sentry.setUser({ id, email })
  }, user)

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <GlobalLoader />
      <LoginModalProvider>
        <Header
          patient={patient as Promise<Patient>}
          conversations={conversations as Promise<Conversation[]>}
        />
        <div className="h-outlet">
          <Outlet context={{ currentUser: user }} />
        </div>
      </LoginModalProvider>
    </GrowthBookProvider>
  )
}

export default Sentry.withSentry(App)

export function ErrorBoundary() {
  const error = useRouteError()
  const { COOKIE_DOMAIN } = getAppGlobals()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAccessDeniedError(error)) {
      deleteSessionCookie(COOKIE_DOMAIN)
      navigate(Routes.LOGIN, {
        state: { isForceLogout: true },
      })
    }
  }, [error])

  if (isAccessDeniedError(error)) {
    return <Loader />
  }

  Sentry.captureRemixErrorBoundaryError(error)
  return <ErrorFallback />
}
