import { FetcherWithComponents } from '@remix-run/react'
import { Button, Loader, TextInput } from '~/design-system'
import { useOnFetcherLoaded } from '~/lib/fetcherStatus'

function SignIn({
  fetcher,
  changeToForgotPasswordForm,
  closeModal,
  email,
  setEmail,
  alertMessage,
}: {
  fetcher: FetcherWithComponents<{ errorMessage: string }>
  changeToForgotPasswordForm: () => void
  closeModal: () => void
  email: string
  setEmail: (value: string) => void
  alertMessage: React.ReactNode
}) {
  const isLoading = fetcher.state !== 'idle'
  const errorMessage = fetcher.data?.errorMessage

  useOnFetcherLoaded(() => {
    if (!errorMessage) closeModal()
  }, fetcher)

  return (
    <div className="flex flex-col justify-center items-center p-8 lg:p-12 gap-y-2 w-full">
      {isLoading && <Loader />}
      <h2 className="body-xl text-neutral-60-primary-text">Sign in</h2>
      <h3 className="body-md text-neutral-50-secondary-text">
        Input your details below.
      </h3>
      <div
        className="body-lg text-neutral-50-secondary-text text-center"
        data-testid="login-alertmessage"
      >
        {alertMessage}
      </div>
      <fetcher.Form
        method="post"
        action="/api/sign-in"
        className="flex flex-col gap-y-6 w-full mt-2"
      >
        <TextInput
          label="Email"
          size="small"
          required
          name="email"
          type="email"
          data-testid="login-email-input"
          value={email}
          onChange={(value) => setEmail(value)}
        />
        <TextInput
          label="Password"
          size="small"
          required
          name="password"
          type="password"
        />
        {errorMessage && (
          <p className="text-red-500 text-center text-sm mt-2">
            {errorMessage}
          </p>
        )}
        <a
          role="button"
          className="text-xs text-neutral-50-secondary-text underline self-end"
          onClick={() => changeToForgotPasswordForm()}
        >
          Forgotten your password?
        </a>
        <Button fullWidth submit disabled={isLoading}>
          Sign In
        </Button>
      </fetcher.Form>
    </div>
  )
}

export default SignIn
