import { PropsWithChildren, useState } from 'react'
import LoginModalContext from './LoginModalContext'
import LoginModal from './LoginModal'

const LoginModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [alertMessage, setAlertMessage] = useState<React.ReactNode>(null)

  const showAndSetAlertMessage = (alertMessageToDisplay?: React.ReactNode) => {
    setAlertMessage(alertMessageToDisplay)
    setShowLoginModal(true)
  }

  return (
    <LoginModalContext.Provider
      value={{
        showLoginModal: showAndSetAlertMessage,
      }}
    >
      {children}
      <LoginModal
        isOpen={showLoginModal}
        alertMessage={alertMessage}
        setShowLoginModal={setShowLoginModal}
        setAlertMessage={setAlertMessage}
      />
    </LoginModalContext.Provider>
  )
}

export default LoginModalProvider
